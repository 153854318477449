import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import cytoscape from "cytoscape";
import elk from "cytoscape-elk";
import { saveAs } from "file-saver";
import axios from "axios";
import {
	FacebookIcon,
	FacebookShareButton,
	ViberIcon,
	ViberShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from "react-share";
import { FaRegBookmark, FaRegWindowClose } from "react-icons/fa";

import images from "../../api/imagesConfig.js";
import { cyStyle } from "../../data/tree";
import URLS from "../../api/url";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import SignInPopup from "../layouts/SignInPopup";
import Legends from "./Tree/Legends";
import { cypherMessage } from "../utilities/CypherMessage.jsx";
import { resetUserData } from "../utilities/Reset.js";
import HistoryLine from "./Tree/HistoryLine.jsx";

const TreePage = () => {
	let navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();
	const [facebookShareUrl, setFacebookShareUrl] = useState("");
	const shareQuote = "namawali : हाम्रो बंगसवाली";
	const shareHashTags = "#namawali #mylimbuculture";
	let downloadImageResource = images.icons["download"];
	let treeImageUrl = images.icons["tree"];

	const queryLang = searchParams.get("lang");

	const [isAdmin, setIsAdmin] = useState(false);
	const [firstname, setFirstname] = useState("");
	const [firstnameNepali, setFirstnameNepali] = useState("");
	const [gender, setGender] = useState("male");
	const [order, setOrder] = useState(0);
	const [isFemale, setIsFemale] = useState(false);
	const [isWife, setIsWife] = useState(false);
	const [femaleSurname, setFemaleSurname] = useState("");
	const [addLabel, setAddLabel] = useState("Child");

	const [personId, setPersonId] = useState("");
	const [lang, setLang] = useState(queryLang);
	const [person, setPerson] = useState(null);
	const [isPersonSaving, setIsPersonSaveing] = useState(false);
	const [people, setPeople] = useState([]);
	const [casteId, setCasteId] = useState("");
	const [treeData, setTreeData] = useState([]);
	const [cyobj, setCyobj] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [showNepali, setShowNepali] = useState(queryLang === "nepali");

	const [showPopup, setShowPopup] = useState("");
	const [parentHierarchy, setParentHierarchy] = useState([]);

	const [showBookmark, setShowBookmark] = useState(true);

	var isTouched = false;
	var isDragging = false;

	// get list of people from server
	const getPeople = () => {
		if (casteId.length <= 0) return;
		const limbuCasteId = URLS.global.limbu;
		const url =
			URLS.base_url +
			URLS.people.base +
			"?caste=" +
			limbuCasteId +
			"&subcaste=" +
			casteId;
		const token = localStorage.getItem("accessToken");

		if (token === null) return [];

		const localPeople = localStorage.getItem("people-" + casteId);

		if (localPeople != null) {
			let message = cypherMessage(localPeople);
			let tempPeople = JSON.parse(message);
			setPeople(tempPeople);
			return;
		}

		const headers = {
			headers: { authorization: `Bearer ${token}` },
		};

		axios
			.get(url, headers)
			.then((res) => {
				const result = res.data;
				if (res.data.success) {
					const cypheredPeople = result.people;
					let message = cypherMessage(cypheredPeople);
					let tempPeople = JSON.parse(message);
					setPeople(tempPeople);
					setIsAdmin(result.isAdmin);
					localStorage.setItem("people-" + casteId, cypheredPeople);
				} else {
					setPeople([]);
				}
			})
			.catch((error) => {
				if (error.code === "ERR_BAD_REQUEST") {
					// access token has expired
					console.error("access token has expired, relogin");
					resetUserData(navigate);
				}
			});
	};

	/**
	 * Get the full list of person's father line in single array
	 * @param person
	 * @returns array
	 */
	const renderPersonHierarchy = (person) => {
		// show all the parent of the person up untill the generation is 0 or lowest
		let parents = [];
		let currentPerson = person;
		let generation = person.generation;
		while (generation > 0) {
			let father = people.filter((p) => p.id == currentPerson.father);
			if (father.length) {
				father[0].caste = casteId;
				parents.push(father[0]);
				currentPerson = father[0];
				generation = father[0].generation;
			} else {
				break;
			}
		}
		return parents;
	};

	// save current 'person' as myself
	const bookmarkPerson = () => {
		if (!person || isPersonSaving) return;

		const url = URLS.base_url + URLS.people.base + URLS.people.person;
		const token = localStorage.getItem("accessToken");

		if (token === null) return [];

		const headers = {
			headers: { authorization: `Bearer ${token}` },
		};

		let sendData = {
			casteId: casteId,
			person: person,
			uid: localStorage.getItem("userId"),
		};

		setIsPersonSaveing(true);

		axios
			.post(url, sendData, headers)
			.then((res) => {
				const result = res.data;
				if (result.success) {
				} else {
				}
				setShowPopup("");
				setIsPersonSaveing(false);
				setShowBookmark(true);
			})
			.catch((error) => {
				if (error.code === "ERR_BAD_REQUEST") {
					// access token has expired
					console.error("access token has expired, relogin");
					// resetUserData();
				}
				setShowPopup("");
				setIsPersonSaveing(false);
				setShowBookmark(true);
			});
	};

	const downloadImage = () => {
		saveAs(cyobj.png(), "graph.png");
	};

	const closeModalBox = () => {
		setShowPopup("");
	};

	const handleChildNameChange = (event) => {
		const val = event.target.value;
		setFirstname(val);
	};

	const handleChildNepaliNameChange = (event) => {
		const val = event.target.value;
		setFirstnameNepali(val);
	};

	const handleGenderChange = (e) => {
		const val = e.target.value;

		if (val == "female") {
			setIsFemale(true);
		} else {
			setIsFemale(false);
		}

		setGender(val);
	};

	const handleOrderChange = (event) => {
		const val = event.target.value;
		setOrder(val);
	};

	const handleIsWife = () => {
		let opposite = !isWife;
		setIsWife(opposite);
		if (opposite == true) {
			setAddLabel("Wife");
		} else {
			setAddLabel("Child");
		}
	};

	const handleFemaleSurname = (event) => {
		const val = event.target.value;
		setFemaleSurname(val);
	};

	const handleSaveChild = (e) => {
		const url = URLS.base_url + URLS.people.base + URLS.people.child;
		const token = localStorage.getItem("accessToken");

		if (token === null) return [];

		const headers = {
			headers: { authorization: `Bearer ${token}` },
		};

		let currentSurname = "neyong";
		if (gender == "female" && femaleSurname.length) {
			currentSurname = femaleSurname;
		}

		let sendData = {
			casteId: casteId,
			fatherId: person.id,
			firstname,
			firstnameNepali,
			gender,
			generation: parseInt(person.generation) + 1,
			order: parseInt(order),
			isFemale: isFemale,
			isWife: isWife,
			subcasteId: casteId,
			surname: currentSurname,
			uid: localStorage.getItem("userId"),
		};

		// validation
		let errors = [];
		if (sendData.firstname.length <= 0) {
			errors.push("firstname is required");
		}
		if (sendData.firstnameNepali.length <= 0) {
			errors.push("firstname in Nepali is required");
		}

		if (errors.length) {
			alert("error : " + errors.join(", "));
			return false;
		}

		axios
			.post(url, sendData, headers)
			.then((res) => {
				const result = res.data;
				console.log("result", result);
				if (result.success) {
					// window.location.reload();

					// add new child to people state
					let newPerson = result.person;
					let newPeople = [...people];
					newPeople.push(newPerson);
					setPeople(newPeople);

					// close modal form
					setShowPopup("");
				}
			})
			.catch((error) => {
				if (error.code === "ERR_BAD_REQUEST") {
					// access token has expired
					console.error("access token has expired, relogin");
					// resetUserData();
				}
			});
	};

	const handleRedirectToSearchPerson = () => {
		navigate("/search?caste=" + casteId);
	};

	const handleSelectPerson = (e) => {
		setShowBookmark(false);
		bookmarkPerson();
	};

	const handleLanguageChange = (e) => {
		let val = !showNepali;
		setShowNepali(val);

		let newLang = val === false ? "english" : "nepali";
		setLang(newLang);
		processPeople(newLang);
	};

	const handleNodeClicked = (id) => {
		let personFound = false;
		// console.log('node clicked : ' + id);
		people.forEach((item) => {
			if (item.id === id) {
				// && item.spouse == undefined && item.gender == "male") {
				// console.log('person', item);

				// find father
				let father = people.filter((p) => p.id == item.father);
				let fatherId = undefined;
				item.fatherName = "";
				if (father.length) {
					fatherId = father[0].id;
					item.fatherName = father[0].firstname;
					if (lang === "nepali") {
						item.fatherName = father[0].firstname_nepali;
					}
				}
				// find mother
				let mother = people.filter((p) => {
					if (p.spouse !== undefined && p.spouse == fatherId) {
						return p;
					}
				});
				let motherId = undefined;
				item.motherName = "";
				if (mother.length) {
					motherId = mother[0].id;
					item.motherName = mother[0].firstname;
					if (lang === "nepali") {
						item.motherName = mother[0].firstname_nepali;
					}
				}
				// find children
				let children = people.filter((p) => p.father == item.id);
				item.children = children.length;

				setPerson(item);
				personFound = true;

				let hierarchy = renderPersonHierarchy(item);
				setParentHierarchy(hierarchy);
			}
		});
		if (personFound) {
			setShowPopup("show");
		}
	};

	// create cytoscape obj
	const createCyObj = () => {
		if (cy) cy.destroy();

		let treeElement = document.getElementById("cy");

		if (treeElement === null) return;

		var cy = cytoscape({
			container: treeElement, // container to render in

			elements: treeData,

			style: cyStyle,

			layout: {
				name: "elk",
				// name: 'breadthfirst',
				directed: true,
				elk: {
					"elk.direction": "DOWN",
					algorithm: "mrtree",
					nodeOrdering: "default",
				},
				padding: 10,
				spacingFactor: 1.5,
				avoidOverlap: true,
				nodeDimensionsIncludeLabels: true,
				// depthSort: function(a, b) {
				//     return a.data('order') - b.data('order');
				// }
			},

			minZoom: 0.5,
			maxZoom: 2,
		});

		cy.nodes().on("click", function (e) {
			var ele = e.target;
			var id = ele.id();

			handleNodeClicked(id);
		});

		cy.nodes().on("touchstart", function (e) {
			isTouched = true;
		});

		cy.nodes().on("touchmove", function (e) {
			if (isTouched) {
				// dragging
				isDragging = true;
			}
		});

		cy.nodes().on("touchend", function (e) {
			var ele = e.target;
			var id = ele.id();

			// not a drag but a click (tap) event
			if (isDragging === false && id.length) {
				handleNodeClicked(id);
			}

			// reset
			isDragging = false;
			isTouched = false;
		});

		// making sure cy is rendered first
		cy.on("ready", () => {
			// console.log('cy is ready')
			if (personId) {
				var centerNode = cy.$("#" + personId);
				// console.log(centerNode)
				centerNode.style({
					"background-color": "#6558F5",
					width: "60px",
					height: "60px",
				});
				cy.center(centerNode, 100);
				cy.zoom({
					level: 1,
					position: centerNode.position(),
				});
			}
		});

		// animations
		cy.on("mouseover", "node", (event) => {
			var node = event.target;
			event.cy.container().style.cursor = "pointer";
			node.animation({
				style: {
					"font-size": "22px",
					"font-weight": "bold",
					width: "60px",
					height: "60px",
				},
				duration: 200,
			}).play();
		});
		cy.on("mouseout", "node", function (event) {
			var node = event.target;
			event.cy.container().style.cursor = "default";
			node.animation({
				style: {
					"font-size": "18px",
					"font-weight": "normal",
					width: "30px",
					height: "30px",
				},
				duration: 200,
			}).play();
		});

		setCyobj(cy);
	};

	const capitalize = (str) => {
		if (str == undefined) return "";
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	// converts fetched data from server into usable data for tree
	const processPeople = (lang) => {
		// console.log("processPeople() - people", people);
		if (people.length <= 0) return;

		var list = {
			nodes: [],
			edges: [],
		};

		// console.log(JSON.stringify(people));

		// sort people array by order
		people.sort(function (a, b) {
			return a.order - b.order;
		});

		// create nodes
		people.map((item, key) => {
			let fullName = capitalize(item.firstname);
			let nameDisplay = "";
			if (typeof item.surname != "undefined") {
				// fullName += ' ' + item.surname;
			}
			if (lang == "nepali") {
				fullName = item.firstname_nepali;
				//TODO fix this bug: name is repeated twice
				if (fullName == "नरबहादुरनरबहादुर") {
					fullName = "नरबहादुर";
				}
			}
			if (item.generation != undefined) {
				if (item.generation) {
					nameDisplay += `${item.generation}. `;
				}
			}
			nameDisplay += fullName;
			let itemOrder = item.order;
			if (item.order != undefined) {
				nameDisplay += ` ( ${itemOrder} )`;
			}
			let node = {
				data: {
					id: item.id,
					name: item.firstname,
					label: nameDisplay,
					spouse: item.spouse,
					gender: item.gender,
					order: itemOrder,
				},
			};

			if (typeof item.spouse !== "undefined") {
				node["data"]["wife"] = "yes";
			}
			list["nodes"].push(node);
		});

		// create edges
		people.map((item) => {
			// edge for father and son
			if (item.father.length) {
				let edge = {
					data: {
						id: `${item.father}-${item.id}`,
						source: item.father,
						target: item.id,
						relation: "children",
					},
				};
				list["edges"].push(edge);
			}
			// edge for husband and wife
			if (typeof item.spouse !== "undefined" && item.spouse.length) {
				let edge2 = {
					data: {
						id: `${item.spouse}-${item.id}`,
						source: item.spouse,
						target: item.id,
						relation: "married",
					},
				};
				list["edges"].push(edge2);
			}
			return "";
		});

		setTreeData(list);
	};

	useEffect(() => {
		setIsLoading(false);
	}, []);

	useEffect(() => {
		cytoscape.use(elk);
		createCyObj();
	}, [treeData]);

	useEffect(() => {
		let id = searchParams.get("id");
		setLang(searchParams.get("lang"));
		setPersonId(id);
		let casteId = searchParams.get("caste");
		if (casteId) {
			setCasteId(casteId);
			setFacebookShareUrl(
				`https://www.namawali.com/tree?id=${id}&casteId=${casteId}`
			);
		}
	}, []);

	useEffect(() => {
		getPeople();
	}, [casteId]);

	useEffect(() => {
		if (people.length) {
			setIsLoading(true);
			processPeople(lang);
		}
	}, [people]);

	useEffect(() => {
		if (personId.length) {
			const newLang = showNepali ? "nepali" : "english";
			setSearchParams({ id: personId, caste: casteId, lang: newLang });
		}
	}, [showNepali]);

	useEffect(() => {
		const handleKeyPress = (e) => {
			if (e.key === "Escape") {
				closeModalBox();
			}
		};
		if (showPopup === "show") {
			window.addEventListener("keydown", handleKeyPress);
		} else {
			window.removeEventListener("keydown", handleKeyPress);
		}

		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, [showPopup]);

	return (
		<div className="page page-tree">
			<Header />

			{isLoading === false ? (
				<div className="loader-container">
					<span className="loader"></span>
				</div>
			) : (
				<>
					<div className="tree-header">
						<img src={treeImageUrl} alt="family tee logo" />
						<h1 className="page-title">Family tree</h1>
					</div>

					{/* Cyptoscape Container */}
					<div className="block-wrapper">
						<div className="block block-tree">
							<div id="btn-download" onClick={downloadImage}>
								<img
									src={downloadImageResource}
									alt="tree-downloader"
									title="download"
								/>
							</div>
							<div id="btn-share">
								<div
									className="share-item"
									style={{ display: "none" }}
								>
									<WhatsappShareButton
										url={facebookShareUrl}
										quote={shareQuote}
										hashtag={shareHashTags}
									>
										<WhatsappIcon size={32} round={true} />
									</WhatsappShareButton>
								</div>
								<div
									className="share-item"
									style={{ display: "none" }}
								>
									<FacebookShareButton
										url={facebookShareUrl}
										quote={shareQuote}
										hashtag={shareHashTags}
									>
										<FacebookIcon size={32} round={true} />
									</FacebookShareButton>
								</div>
								<div className="share-item">
									<ViberShareButton
										url={facebookShareUrl}
										title={shareQuote}
									>
										<ViberIcon size={32} round={true} />
									</ViberShareButton>
								</div>
							</div>
							<div id="search-person-box">
								<button onClick={handleRedirectToSearchPerson}>
									Search person
								</button>
							</div>
							<div
								id="btn-change-lang"
								className="toggle-button-cover"
							>
								<div>
									<p>English - Nepali</p>
								</div>
								<label className="switch">
									<input
										type="checkbox"
										checked={showNepali}
										onChange={handleLanguageChange}
									/>
									<span className="slider round"></span>
								</label>
							</div>

							{/* Cytoscape Container - canvas */}
							<div id="cy"></div>
						</div>
					</div>

					<div
						className={"modal-box-container " + showPopup}
						id="modal-box-person-selector"
					>
						<div className="modal-box-wrapper">
							<div className="close" onClick={closeModalBox}>
								<FaRegWindowClose />
							</div>
							<div className="content person-info">
								<div className="side left-side">
									{person ? (
										<>
											<h2>{person.firstname}</h2>
											{showBookmark && (
												<div
													className="btn"
													onClick={handleSelectPerson}
												>
													<FaRegBookmark
														style={{ fontSize: 28 }}
													/>
												</div>
											)}
											<div className="person-info-box">
												<p>Generation</p>
												<p>{person.generation}</p>
												<p>ID</p>
												<p>{person.id}</p>
												<p>Father</p>
												<p>{person.fatherName}</p>
												<p>Mother</p>
												<p>{person.motherName}</p>
												<p>Children</p>
												<p>{person.children}</p>
												<p>Order</p>
												<p>{person.order}</p>
											</div>
										</>
									) : (
										<>
											<p>No person selected yet.</p>
										</>
									)}
								</div>

								<div className="side right-side">
									<div className="form-wrapper-children">
										{isAdmin && (
											<div className="add-child-form">
												<p className="add-child-header">
													Add {addLabel}
												</p>
												<p></p>
												<p>Firstname</p>
												<div className="form-item">
													<input
														onChange={
															handleChildNameChange
														}
														placeholder="firstname"
													/>
												</div>

												<p>Firstname nepali</p>
												<div className="form-item">
													<input
														onChange={
															handleChildNepaliNameChange
														}
														placeholder="firstname nepali"
													/>
												</div>

												<p>Gender</p>
												<div className="form-item form-item-gender">
													<label>
														<input
															type="radio"
															name="gender"
															value="male"
															checked={
																gender == "male"
															}
															onChange={
																handleGenderChange
															}
														/>
														<span>Male</span>
													</label>

													<label>
														<input
															type="radio"
															name="gender"
															value="female"
															checked={
																gender ==
																"female"
															}
															onChange={
																handleGenderChange
															}
														/>
														<span>Female</span>
													</label>
												</div>

												<p>Order</p>
												<div className="form-item">
													<input
														onChange={
															handleOrderChange
														}
														type="number"
														min="1"
														placeholder="order"
													/>
												</div>

												{isFemale && (
													<>
														<p>Is spouse / wife?</p>
														<div className="form-item form-checkbox">
															<label>
																<input
																	type="checkbox"
																	name="isWife"
																	value="1"
																	checked={
																		isWife
																	}
																	onChange={
																		handleIsWife
																	}
																/>
															</label>
														</div>

														<p>Surname</p>
														<div className="form-item form-item-surname">
															<input
																value={
																	femaleSurname
																}
																onChange={
																	handleFemaleSurname
																}
																placeholder="female surname"
															/>
														</div>
													</>
												)}

												<button
													onClick={handleSaveChild}
													className="btn-add-child"
												>
													Add {addLabel}
												</button>
											</div>
										)}

										{!isAdmin &&
											parentHierarchy.length > 0 && (
												<HistoryLine
													parents={parentHierarchy}
													lang={lang}
												/>
											)}

										{!isAdmin && person && (
											<p id="not-allowed">
												{/* {person.firstname} */}
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}

			<Legends />

			<Footer />

			<SignInPopup />
		</div>
	);
};

const ChildAddForm = () => {
	return <></>;
};

export default TreePage;
