import CryptoJS from 'crypto-js';

export const cypherMessage = (text) => {
    if (text === null) return;
    
    const laca = '7f8b606eb4bdc173c1a205fe84b05889';
    const byaa = CryptoJS.AES.decrypt(text, laca);
    const result = byaa.toString(CryptoJS.enc.Utf8);

    return result;
}