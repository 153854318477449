import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { castes } from "../../data/castes";
import Breadcrumb from "../layouts/Breadcrumb";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import CasteSearchResult from "../search/CasteSearchResult";
import URLS from "../../api/url";
import SignInPopup from "../layouts/SignInPopup";
import { cypherMessage } from "../utilities/CypherMessage";
import { resetUserData } from "../utilities/Reset";

const CasteSelectPage = () => {
	let navigate = useNavigate();

	const [query, setQuery] = useState("");
	const [searchOptions, setSearchOptions] = useState([]);
	const [searchResult, setSearchResult] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [breadcrumbData, setBreadcrumbData] = useState([]);

	const handleCasteClick = (caste) => {
		localStorage.setItem("caste", caste.id);
		// go to search people page
		navigate("/search?caste=" + caste.id + "&name=" + caste.name);
	};

	const handleSearch = (event) => {
		let value = event.target.value;
		setQuery(value);
	};

	const getSubCastes = () => {
		const limbuCasteId = URLS.global.limbu;
		const url =
			URLS.base_url + URLS.castes.subcastes + "?caste=" + limbuCasteId;
		const token = localStorage.getItem("accessToken");

		if (token === null) return [];

		const headers = {
			headers: { authorization: `Bearer ${token}` },
		};

		axios
			.get(url, headers)
			.then((res) => {
				const result = res.data;
				if (res.data.success) {
					localStorage.setItem("subcastes", result.subcastes);
					let message = cypherMessage(result.subcastes);
					let tempSubcastes = JSON.parse(message);
					setSearchOptions(tempSubcastes);
				} else {
					setSearchOptions([]);
				}

				setIsLoading(false);
			})
			.catch((error) => {
				if (error.code == "ERR_BAD_REQUEST") {
					// access token has expired
					console.error("access token has expired, relogin");
					resetUserData(navigate);
				}
			});
	};

	// sort people alphabetically
	let tempSearchOptions = searchOptions;
	tempSearchOptions = searchOptions.sort((a, b) =>
		a.name.localeCompare(b.name)
	);

	let optionsHtml = tempSearchOptions.map((sub, key) => {
		return (
			<div
				className="option"
				key={key}
				onClick={() => handleCasteClick(sub)}
			>
				<p>{sub.name}</p>
			</div>
		);
	});

	useEffect(() => {
		let result = searchOptions.filter((caste) => {
			if (query === "") {
				return "";
			} else if (caste.name.toLowerCase().includes(query.toLowerCase())) {
				return caste;
			}
		});
		setSearchResult(result);

		const path = [
			{
				label: "Select Subcaste",
				path: "/caste/select",
				class: "active",
			},
			{ label: "Select Person", path: "/search", class: "" },
		];
		setBreadcrumbData(path);
	}, [query]);

	useEffect(() => {
		setIsLoading(false);

		let localSubcastes = localStorage.getItem("subcastes");
		if (localSubcastes !== null) {
			let message = cypherMessage(localSubcastes);
			let tempSubcastes = JSON.parse(message);
			setSearchOptions(tempSubcastes);
			setIsLoading(false);
		} else {
			getSubCastes();
		}
	}, []);

	return (
		<div className="page">
			<Header />

			{/* <Breadcrumb data={breadcrumbData} /> */}

			{isLoading === true ? (
				<div className="loader-container">
					<span className="loader"></span>
				</div>
			) : (
				<>
					<div className="content">
						<h1 className="step-header">
							Step 1. Select Sub-caste
						</h1>

						<div className="stepper-wrapper">
							<div className="stepper-item completed">
								<div className="step-counter">1</div>
								<div className="step-name">Select subcaste</div>
							</div>
							<div className="stepper-item">
								<div className="step-counter">2</div>
								<div className="step-name">Select person</div>
							</div>
							<div className="stepper-item active">
								<div className="step-counter">3</div>
								<div className="step-name">
									View the full family tree
								</div>
							</div>
						</div>

						<div className="block-wrapper">
							<h2>Options</h2>
							{optionsHtml.length > 0 ? (
								<div className="options-wrapper">
									{optionsHtml}
								</div>
							) : (
								<div>No options available</div>
							)}
						</div>

						<div className="space-block"></div>

						<div className="block-wrapper">
							<h2>Search</h2>

							<div className="block block-caste-select">
								<input
									placeholder="Type subcaste to search"
									onChange={handleSearch}
									className="form-item-search"
								/>

								<div className="result-area">
									<CasteSearchResult
										data={searchResult}
										handleCasteClick={handleCasteClick}
									/>
								</div>
							</div>
						</div>
					</div>
				</>
			)}

			<Footer />

			<SignInPopup />
		</div>
	);
};

export default CasteSelectPage;
