import { useQuery, useQueryClient } from 'react-query'
import { getUserInfo } from '../api/UserInfo'


const LoginTreeLink = ({ userId }) => {

    const queryClient = useQueryClient()

    // Queries
    const { isLoading, data } = useQuery(['userinfo', userId], getUserInfo)

    return (
        <>
            {isLoading ? (
                <>loading...</>
            ) : (
                <SmallLink show={data.length} url={data} />
            )}
            
        </>
    )
}

const SmallLink = ({ show, url }) => {

    return (
        <>
            {show ? (
                <a className="btn" href={url}>Open My Tree</a>
            ) : (
                <></>
            )}
        </>
    )

}

export default LoginTreeLink;