import { cypherMessage } from "./CypherMessage";

export const resetUserData = (navigate) => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userDisplayName');
    localStorage.removeItem('photoUrl');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userId');

    let subs = localStorage.getItem('subcastes');
    if (subs) {
        let subcastes = JSON.parse(cypherMessage(subs));
        subcastes.forEach(subcaste => {
            localStorage.removeItem(`people-${subcaste.id}`);
        });
        localStorage.removeItem('caste');
        localStorage.removeItem('subcastes');
    }

    navigate('/login');
};