import axios from "axios"

import URLS from "../../api/url";

export const getUserInfo = async (params) => {

    let tempUrl = '';
    let userId = params.queryKey[1];

    const url = URLS.base_url + URLS.user.info + '?userId=' + userId;
        const token = localStorage.getItem('accessToken');

        if (token === null || typeof userId === 'undefined' || userId == undefined || userId == 'undefined') {
            return '';
        }

        const headers = {
            headers:  
            { authorization: `Bearer ${token}` }
        };

        const result = await axios.get(url, headers)
            .then((res) => {
                const result = res.data;
                if (res.data.success) {
                    let tempUser = result.user;
                    tempUrl = 'tree?id=' + tempUser.person_id + '&caste=' + tempUser.caste_id + '&lang=english';
                } else {
                    throw new Error('no url')
                }
            })
            .catch((error) => {
                if (error.code == 'ERR_BAD_REQUEST') {
                    // 
                } else {
                    return false;
                }
    });
    
    return tempUrl
}